<template>
  <MainLayout
    bg-color="white"
    no-content-padding
    scroll-toolbar
  >
    <template #toolbar>
      <div class="relative flex items-center justify-between px-5 py-3.5 border-b border-border-normal">
        <div class="flex items-center">
          <img
            src="../../assets/icons/lensIcon.png"
            class="w-10 h-10 rounded-md"
          >
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Lens
            </BaseText>
          </div>
        </div>
        <button
          class="flex items-center gap-1.5 absolute left-1/2 transform -translate-x-1/2
        pl-1 py-1 pr-3 rounded-full border border-border-normal hover:bg-neutral-25 hover:border-transparent"
          style="transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, opacity 100ms ease-in-out;"
        >
          <img
            src="../../assets/icons/spyder-token-disabled.png"
            class="w-5 h-5"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            0 / 2 credits
          </BaseText>
        </button>
        <BaseButton
          primary
          icon-only
          @click="showLensCreationModal = true"
        >
          <span class="flex pr-1.5">
            <PlusIcon stroke="white" />
            <BaseText
              type="label"
              size="sm"
              class="text-white"
            >
              Connect Account
            </BaseText>
          </span>
        </BaseButton>
      </div>
    </template>

    <template #content>
      <LensCreationModal
        v-if="showLensCreationModal"
        @complete="handleLensCreation"
        @close="showLensCreationModal = false"
      />
      <LensEmptyState
        v-if="!lenses && false"
        @createLens="showLensCreationModal = true"
      />
      <div
        v-else
        class="p-4"
      >
        <div
          class="brand-grid-template rounded-lg bg-neutral-10"
        >
          <div class="group pl-12 pr-4 py-2 cursor-pointer flex items-center gap-1">
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              Name
            </BaseText>
          </div>
          <div class="px-3 py-2 cursor-pointer flex items-center gap-1 group">
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              Data Since
            </BaseText>
          </div>
        </div>
        <div>
          <!--  -->
          <div
            v-for="(lens, index) in lenses"
            :key="`lens-${index}` "
            class="w-full"
          >
            <router-link
              :to="{ name: 'LensDashboardView', params: { lensId: lens.id } }"
              class="brand-grid-template pr-0.5 cursor-pointer rounded-lg transition-colors hover:bg-neutral-10"
            >
              <div class="flex items-center gap-3 px-2 py-1">
                <div class="w-7 h-7 rounded-md overflow-hidden flex-shrink-0">
                  <img
                    src="@/assets/icons/lensIcon.png"
                    class="w-7 h-7 rounded-md"
                  >
                </div>
                <BaseText
                  type="label"
                  size="sm"
                  class="truncate"
                  :class="lens.id ? 'text-text-muted' : 'text-text-disabled'"
                  :style="lens.id ? '' : 'font-weight: 400;'"
                >
                  {{ lens.company_name }}
                </BaseText>
              </div>
              <div class="px-3 py-2">
                <BaseText
                  type="body"
                  size="sm"
                  class=" text-text-subdued"
                >
                  {{ formatDate(lens?.created_at) }}
                </BaseText>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import LensAPI from '@/api/lens'
import MainLayout from '../../layouts/MainLayout.vue'
import LensEmptyState from '../../components/lens/LensEmptyState.vue'
import LensCreationModal from '../../components/lens/lensCreationModal/LensCreationModal.vue'
import PlusIcon from '../../components/globals/Icons/PlusIcon.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'LensView',
  components: {
    LensCreationModal,
    LensEmptyState,
    MainLayout,
    PlusIcon
  },
  data: () => ({
    showLensCreationModal: false,
    lenses: []
  }),
  computed: {
    ...mapGetters('BoardsModule', ['getFolders']),
    sortedFolders () {
      // We want empty folders (besides the default folder) to be at the bottom
      const nonEmptyFolders = this.getFolders.filter(folder => (folder?.lenses?.length || 0) > 0)
      const emptyFolders = this.getFolders.filter(folder => (folder?.lenses?.length || 0) === 0)
      return [{ id: null, name: 'Default Folder' }, ...nonEmptyFolders, ...emptyFolders]
    }
  },
  async mounted () {
    this.lenses = (await LensAPI.Lens.getAll())?.data
  },
  methods: {
    async handleLensCreation (payload) {
      try {
        await LensAPI.Lens.create(payload)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    },
    getSortedLensesInFolder (folderId) {
      const lenses = this.getLensesInFolder(folderId, this.last_updated)
      return lenses
    },
    getLensesInFolder (folderId) {
      return this.lenses.filter(lens => lens.folder_id === folderId)
    },
    formatDate (dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }
  }
}
</script>

<style scoped>
/* Hide the last-checked column on narrower viewports */
@media (max-width: 1600px) {
  .last-checked-column {
    display: none;
  }

  .brand-grid-template {
    grid-template-columns: 250px 180px 320px 1fr 32px !important;
  }
}

.folder-header-grid-template {
  display: grid;
  grid-template-columns: 220px 235px 1fr;
  align-items: center;
}

.brand-grid-template {
  display: grid;
  grid-template-columns: 250px 146px 180px 320px 1fr 32px;
  align-items: center;
}
</style>
